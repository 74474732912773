import React from "react";
import { Row, Container, Col } from "reactstrap";
import { Appfooter } from "components/Appfooter";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";

import "./Countryorg.scss";
export const Countryorg = () => {
  return (
    <>
      <Container fluid className="main_continer">
        <Row>
          <Row className="global-ef">
            <Card.Title className="global-ef-title">
              Country Organization Management System Overview
            </Card.Title>
            <div className="col-12">
              <Table striped hover className="border">
                <tbody>
                  <tr>
                    <th>
                      <strong>Issuing Country​</strong>
                    </th>
                    <th>
                      <strong>Regulations in IMS IT Tools</strong>
                    </th>
                    <th>
                      <strong>Link to additional information</strong>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <a
                        target={"_blank"}
                        href="https://bayernet.int.bayer.com/en/benelux/home"
                      >
                        ​Benelux
                      </a>
                    </td>
                    <td>
                      <a target={"_blank"} href="https://go.cnb/bnl-regs">
                        All Regulations in IMS (go/BNL-Regs)
                      </a>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      ​
                      <a
                        target={"_blank"}
                        href="https://bayernet.int.bayer.com/en/brazil/home"
                      >
                        Brazil
                      </a>
                    </td>
                    <td>
                      <a target={"_blank"} href="https://go.cnb/br-regs">
                        All Regulations in IMS (go/BR-Regs)
                      </a>
                      <a
                        target={"_blank"}
                        href="http://sp-coll-bbs.bayer-ag.com/sites/030981/Pages/searchresult.aspx?query=%20AND%20%28%28bySourceSystemTitle%3a%22%25C7%2582%25C7%25824c696665446f63%22%29%20AND%20%28askSourceSubLevel%3a%22%25C7%2582%25C7%2582524547532d42523031202d20524547532d42523031%22%29%29&amp;keyword=%2a&amp;selectedFilters=chk_%C7%82%C7%824c696665446f63_bySourceSystemTitle%3bchk_%C7%82%C7%82524547532d42523031202d20524547532d42523031_askSourceSubLevel%3b&amp;function=%22REGS-BR01%22&amp;tabName=undefined"
                      >
                        GMP/GDP Regulations (REGS-BR01)
                      </a>
                    </td>
                    <td>
                      <a
                        target={"_blank"}
                        href="https://sispol.cpl.bayer.br.intranet/Login"
                      >
                        ​SisPol
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      ​
                      <a
                        target={"_blank"}
                        href="https://bayernet.int.bayer.com/de-de/country-group-ro-bg-md/home"
                      >
                        Bulgaria
                      </a>
                    </td>
                    <td>
                      ​​
                      <a target={"_blank"} href="https://go.cnb/bg-regs">
                        All Regulations in IMS&nbsp;(go/BG-Regs)
                      </a>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <a
                        target={"_blank"}
                        href="https://bayernet.int.bayer.com/en/canada/home"
                      >
                        ​Canada
                      </a>
                    </td>
                    <td>
                      <a target={"_blank"} href="https://go.cnb/ca-regs">
                        All Regulations in IMS (go/CA-Regs)
                      </a>
                    </td>
                    <td>
                      <a
                        target={"_blank"}
                        href="https://bayergroup.sharepoint.com/sites/022446/SitePages/Home.aspx"
                      >
                        Canada Regulation Platform
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a
                        target={"_blank"}
                        href="https://bayernet.int.bayer.com/en/greater-china"
                      >
                        China
                      </a>
                    </td>
                    <td></td>
                    <td>
                      <a
                        target={"_blank"}
                        href="https://chinaregulationonline.bayer.cnb/Regulation/Overview"
                      >
                        China Regulation Online
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      ​​
                      <a
                        target={"_blank"}
                        href="https://bayernet.int.bayer.com/de-de/central-eastern-europe/czech-republic/home"
                      >
                        Czech Republic
                      </a>
                    </td>
                    <td>
                      ​
                      <a target={"_blank"} href="https://go.cnb/cz-regs">
                        All Regulations in IMS (go/CZ-Regs)
                      </a>
                    </td>
                    <td>​</td>
                  </tr>
                  <tr>
                    <td>
                      ​​
                      <a
                        target={"_blank"}
                        href="https://bayernet.int.bayer.com/de-de/france/home"
                      >
                        France
                      </a>
                    </td>
                    <td>
                      ​
                      <a target={"_blank"} href="https://go.cnb/fr-regs">
                        All Regulations in IMS (go/FR-Regs)
                      </a>
                    </td>
                    <td>​</td>
                  </tr>
                  <tr>
                    <td>
                      ​
                      <a
                        target={"_blank"}
                        href="https://bayernet.int.bayer.com/en/germany/home"
                      >
                        Germany
                      </a>
                    </td>
                    <td>
                      <a target={"_blank"} href="https://go.cnb/de-regs">
                        All Regulations&nbsp;in IMS (go/DE-Regs)
                      </a>
                    </td>
                    <td>​</td>
                  </tr>
                  <tr>
                    <td>
                      <a
                        target={"_blank"}
                        href="https://bayernet.int.bayer.com/en/greece/home"
                      >
                        ​Greece
                      </a>
                    </td>
                    <td>
                      <a target={"_blank"} href="https://go.cnb/gr-regs">
                        All Regulations&nbsp;in IMS (go/GR-Regs)
                      </a>
                    </td>
                    <td>​</td>
                  </tr>
                  <tr>
                    <td>
                      ​
                      <a
                        target={"_blank"}
                        href="https://bayernet.int.bayer.com/de-de/greater-china/hong-kong-sar/home"
                      >
                        Hong Kong
                      </a>
                    </td>
                    <td>​</td>
                    <td>
                      <a
                        target={"_blank"}
                        href="http://hkwebapp.ap.bayer.cnb:8080/"
                      >
                        Hong Kong Regulation System
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      ​
                      <a
                        target={"_blank"}
                        href="https://bayernet.int.bayer.com/de-de/central-eastern-europe/hungary/home"
                      >
                        Hungary
                      </a>
                    </td>
                    <td>
                      <a target={"_blank"} href="https://go.cnb/hu-regs">
                        All Regulations&nbsp;in IMS (go/HU-Regs)
                      </a>
                      <br />
                      <a
                        target={"_blank"}
                        href="https://ims.int.bayer.com/?size=n_20_n&filters%5B0%5D%5Bfield%5D=country&filters%5B0%5D%5Bvalues%5D%5B0%5D=Nagyigmand%2C%20Hungary%20%28NAG%29&filters%5B0%5D%5Bvalues%5D%5B1%5D=Farmos%2C%20Hungary%20%28FAR%29&filters%5B0%5D%5Btype%5D=any"
                      >
                        Nagyigmand &amp; Farmos Regulations in IMS
                      </a>
                    </td>
                    <td>
                      <a
                        target={"_blank"}
                        href="https://eda-reporting.margo.intranet.cnb/Report/Role/SBR#/EntrySearch/eyJjZ19jb2RlIjpbIkNFRSJdLCJJU09fQ29kZV9TdGFhdCI6WyJIVSJdfQ=="
                      >
                        Margos​ Enacted in Hungary
                      </a>
                      <br />
                      <a
                        target={"_blank"}
                        href="https://ims.int.bayer.com/?size=n_20_n&filters%5B0%5D%5Bfield%5D=source_name_ims&filters%5B0%5D%5Bvalues%5D%5B0%5D=LifeDoc&filters%5B0%5D%5Btype%5D=any&filters%5B1%5D%5Bfield%5D=country&filters%5B1%5D%5Bvalues%5D%5B0%5D=Hungary&filters%5B1%5D%5Btype%5D=any"
                      >
                        Hungary LifeDoc Regulations in&nbsp;IMS
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a
                        target={"_blank"}
                        href="https://bayernet.int.bayer.com/en/israel/home"
                      >
                        ​Israel
                      </a>
                    </td>
                    <td>
                      <a target={"_blank"} href="https://go.cnb/il-regs">
                        All Regulations&nbsp;in IMS (go/IL-Regs)
                      </a>
                    </td>
                    <td>​</td>
                  </tr>
                  {/* <tr><td><a target={"_blank"} href="https://bayernet.int.bayer.com/en/italy/home">​Italy</a></td><td ><a target={"_blank"} href="https://go.cnb/ita-regs">All Regulations in IMS (go/ITA-Regs)</a></td><td ><a target={"_blank"} href="https://ims.int.bayer.com/?size=n_20_n&filters%5b0%5d%5bfield%5d=source_name_ims&filters%5b0%5d%5bvalues%5d%5b0%5d=myDoc&filters%5b0%5d%5btype%5d=any&filters%5b1%5d%5bfield%5d=source_sublevel&filters%5b1%5d%5bvalues%5d%5b0%5d=ITA%20-%20Country%20Platform%20Italy&filters%5b1%5d%5btype%5d=any">​Latest Regulations in IMS (last 30 days)</a></td></tr> */}
                  <tr>
                    <td>
                      <a
                        target={"_blank"}
                        href="https://bayernet.int.bayer.com/en/italy/home"
                      >
                        ​Italy
                      </a>
                    </td>
                    <td>
                      <a target={"_blank"} href="https://go.cnb/ita-regs">
                        All Regulations in IMS (go/ITA-Regs)
                      </a>
                    </td>
                    <td>
                      <a
                        target={"_blank"}
                        href="https://ims.int.bayer.com/#/search/bed11e28-c4e3-47e8-4ce5-0079094898c7"
                      >
                        ​Latest Regulations in IMS (last 30 days)
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>​Japan</td>
                    <td>​</td>
                    <td>
                      <a
                        target={"_blank"}
                        href="https://bayergroup.sharepoint.com/sites/004005/JMR/SitePages/BiJ%20view.aspx"
                      >
                        Japan Regulation SharePoint
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a
                        target={"_blank"}
                        href="https://bayernet.int.bayer.com/de-de/korea/home"
                      >
                        Korea, Republic of (KR)
                      </a>
                    </td>
                    <td>
                      <a target={"_blank"} href="https://go.cnb/kr-regs">
                        All Regulations in IMS (go/KR-Regs)
                      </a>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <a
                        target={"_blank"}
                        href="https://bayernet.int.bayer.com/en/mexico/home"
                      >
                        ​Mexico
                      </a>
                    </td>
                    <td>
                      <a target={"_blank"} href="https://go.cnb/mx-regs">
                        All Regulations&nbsp;in IMS (go/MX-regs)
                      </a>
                    </td>
                    <td>​</td>
                  </tr>
                  <tr>
                    <td>
                      ​
                      <a
                        target={"_blank"}
                        href="https://bayernet.int.bayer.com/de-de/country-group-ro-bg-md/home"
                      >
                        Republic of Moldova
                      </a>
                    </td>
                    <td>
                      ​​
                      <a target={"_blank"} href="https://go.cnb/md-regs">
                        All Regulations in IMS&nbsp;(go/MD-Regs)
                      </a>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      ​​
                      <a
                        target={"_blank"}
                        href="https://bayernet.int.bayer.com/en/nordics/home"
                      >
                        Nordics
                      </a>
                    </td>
                    <td>
                      <a target={"_blank"} href="https://go.cnb/nds-regs">
                        All Regulations in IMS (go/NDS-Regs)
                      </a>
                    </td>
                    <td>​</td>
                  </tr>
                  <tr>
                    <td>
                      ​
                      <a
                        target={"_blank"}
                        href="https://bayernet.int.bayer.com/de-de/paca/home"
                      >
                        PACA
                      </a>
                    </td>
                    <td>
                      ​​
                      <a target={"_blank"} href="https://go.cnb/paca-regs">
                        All Regulations in IMS&nbsp;(go/PACA-Regs)
                      </a>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <a
                        target={"_blank"}
                        href="https://bayernet.int.bayer.com/de-de/central-eastern-europe/poland/home"
                      >
                        Poland
                      </a>
                    </td>
                    <td>
                      <a target={"_blank"} href="https://go.cnb/PL-Regs">
                        All Regulations&nbsp;in IMS (go/PL-Regs)
                      </a>
                    </td>
                    <td>​</td>
                  </tr>
                  <tr>
                    <td>
                      ​
                      <a
                        target={"_blank"}
                        href="https://bayernet.int.bayer.com/en/portugal/home"
                      >
                        Portugal
                      </a>
                    </td>
                    <td>
                      <a target={"_blank"} href="https://go.cnb/pt-regs">
                        All Regulations&nbsp;in IMS (go/PT-Regs)
                      </a>
                    </td>
                    <td>
                      <a
                        target={"_blank"}
                        href="http://sp-appl-emea.azure.cnb/sites/230122/ADFI_ManualOrg/default.aspx"
                      >
                        Manual de Organizaçao
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      ​
                      <a
                        target={"_blank"}
                        href="https://bayernet.int.bayer.com/de-de/country-group-ro-bg-md/home"
                      >
                        Romania
                      </a>
                    </td>
                    <td>
                      ​​
                      <a target={"_blank"} href="https://go.cnb/ro-regs">
                        All Regulations in IMS&nbsp;(go/RO-Regs)
                      </a>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      ​​
                      <a
                        target={"_blank"}
                        href="https://bayernet.int.bayer.com/de-de/central-eastern-europe/czech-republic/home"
                      >
                        Slovakia
                      </a>
                    </td>
                    <td>
                      ​
                      <a target={"_blank"} href="https://go.cnb/sk-regs">
                        All Regulations in IMS (go/SK-Regs)
                      </a>
                    </td>
                    <td>​</td>
                  </tr>
                  {/* <tr><td><a target={"_blank"} href="https://bayernet.int.bayer.com/en/spain/home">Spain</a></td><td>​Regulations are not published and can only be accessed&nbsp;via <a target={"_blank"} href="https://go.cnb/mydoc">with sufficient&nbsp;access.</a></td><td><a target={"_blank"} href="http://sp-appl-emea.azure.cnb/sites/230122/ADFI_ManualOrg/default.aspx">Manual de Organización</a></td></tr> */}
                  <tr>
                    <td>
                      <a
                        target={"_blank"}
                        href="https://bayernet.int.bayer.com/en/spain/home"
                      >
                        Spain
                      </a>
                    </td>
                    <td>
                      <a target={"_blank"} href="https://go.cnb/es-regs">
                        All Regulations in IMS (go/ES-Regs){" "}
                      </a>
                    </td>
                    <td>
                      <a
                        target={"_blank"}
                        href="http://sp-appl-emea.azure.cnb/sites/230122/ADFI_ManualOrg/default.aspx"
                      >
                        Manual de Organización
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      ​
                      <a
                        target={"_blank"}
                        href="https://bayernet.int.bayer.com/en/switzerland/home"
                      >
                        Switzerland
                      </a>
                    </td>
                    <td>
                      ​
                      <a target={"_blank"} href="https://go.cnb/che-regs">
                        All Regulations&nbsp;in IMS (go/CHE-Regs)
                      </a>
                    </td>
                    <td>​</td>
                  </tr>
                  <tr>
                    <td>
                      ​
                      <a
                        target={"_blank"}
                        href="https://bayernet.int.bayer.com/de-de/greater-china/taiwan/home"
                      >
                        Taiwan
                      </a>
                    </td>
                    <td>​</td>
                    <td>
                      <a
                        target={"_blank"}
                        href="http://sp-appl-emea.azure.cnb/sites/020044/default.aspx"
                      >
                        Taiwan Regulation SharePoint
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      ​
                      <a
                        target={"_blank"}
                        href="https://bayernet.int.bayer.com/en/turkey/home"
                      >
                        Turkey
                      </a>
                    </td>
                    <td>
                      ​
                      <a
                        target={"_blank"}
                        href="https://ims.int.bayer.com/?size=n_20_n&filters%5b0%5d%5bfield%5d=source_name_ims&filters%5b0%5d%5bvalues%5d%5b0%5d=myDoc&filters%5b0%5d%5btype%5d=any&filters%5b1%5d%5bfield%5d=source_sublevel&filters%5b1%5d%5bvalues%5d%5b0%5d=TR-IR%20-%20Country%20Platform%20Turkey%20and%20Iran&filters%5b1%5d%5btype%5d=any&filters%5b2%5d%5bfield%5d=country&filters%5b2%5d%5bvalues%5d%5b0%5d=Turkey%20%28TR%29&filters%5b2%5d%5btype%5d=any"
                      >
                        Country&nbsp;Regulations (non GxP)
                      </a>
                      <br />
                      <a
                        target={"_blank"}
                        href="https://ims.int.bayer.com/?size=n_20_n&filters%5b0%5d%5bfield%5d=source_name_ims&filters%5b0%5d%5bvalues%5d%5b0%5d=LifeDoc&filters%5b0%5d%5btype%5d=any&filters%5b1%5d%5bfield%5d=source_sublevel&filters%5b1%5d%5bvalues%5d%5b0%5d=REGS-TR01%20-%20Country%20Organization%20Turkey&filters%5b1%5d%5btype%5d=any"
                      >
                        GMP/GDP&nbsp;Regulations (REGS-TR01)
                      </a>
                    </td>
                    <td>
                      ​
                      <a
                        target={"_blank"}
                        href="https://ims.int.bayer.com/?size=n_20_n&filters%5b0%5d%5bfield%5d=source_name_ims&filters%5b0%5d%5bvalues%5d%5b0%5d=myDoc&filters%5b0%5d%5btype%5d=any&filters%5b1%5d%5bfield%5d=source_sublevel&filters%5b1%5d%5bvalues%5d%5b0%5d=TR-IR%20-%20Country%20Platform%20Turkey%20and%20Iran&filters%5b1%5d%5btype%5d=any&filters%5b2%5d%5bfield%5d=tags&filters%5b2%5d%5bvalues%5d%5b0%5d=Information%20Technology%20%28IT%29&filters%5b2%5d%5btype%5d=any"
                      >
                        IT-Regulations
                      </a>
                      ​
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a
                        target={"_blank"}
                        href="https://bayernet.int.bayer.com/de-de/ukraine/home"
                      >
                        ​Ukraine
                      </a>
                    </td>
                    <td>​</td>
                    <td>
                      <a
                        target={"_blank"}
                        href="http://sp-coll-bhc.bayer-ag.com/sites/220060/guidelines/en/Forms/en.aspx"
                      >
                        Ukraine Regulation SharePoint
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      ​​
                      <a
                        target={"_blank"}
                        href="https://bayernet.int.bayer.com/en/uk-ireland/home"
                      >
                        United&nbsp;Kingdom &amp; Ireland
                      </a>
                    </td>
                    <td>
                      ​
                      <a target={"_blank"} href="https://go.cnb/uki-regs">
                        All Regulations in IMS (go/UKI-Regs)
                      </a>
                    </td>
                    <td>​</td>
                  </tr>
                  <tr>
                    <td>
                      <a
                        target={"_blank"}
                        href="https://bayernet.int.bayer.com/de-de/usa/home"
                      >
                        ​USA/Puerto Rico
                      </a>
                    </td>
                    <td>​</td>
                    <td>
                      ​
                      <a
                        target={"_blank"}
                        href="http://www.bayernet.com/corp/policies/"
                      >
                        U.S. Regulation System
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      ​​
                      <a
                        target={"_blank"}
                        href="https://bayernet.int.bayer.com/de-de/asean/vietnam/home"
                      >
                        Vietnam
                      </a>
                    </td>
                    <td>
                      ​
                      <a target={"_blank"} href="https://go.cnb/vn-regs">
                        All Regulations in IMS (go/VN-Regs)
                      </a>
                    </td>
                    <td>​</td>
                  </tr>
                </tbody>
              </Table>
            </div>

            <Card.Text className="global-ef-title">
              Only&nbsp;content available for every employee and
              contractor&nbsp;is listed in IMS. Content with limited access
              needs to be opened from the IT tools (see&nbsp;IMS Home).&nbsp;
            </Card.Text>
            <Card.Text className="global-ef-title">
              Please don't hesitate to use the Feedback functionality to&nbsp;
              <a target={"_blank"} href="/#/feedback">
                give feedback
              </a>
              &nbsp;or to ask your questions.
            </Card.Text>
          </Row>
        </Row>
      </Container>
      {/* <Appfooter /> */}
    </>
  );
};
