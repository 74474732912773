import React from "react";
import { Appfooter } from "components/Appfooter";

import { Row, Container, Col } from "reactstrap";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";

import "./Globelef.scss";
import { lineHeight, margin } from "@mui/system";

export const Globelef = () => {
  return (
    <>
      <Container fluid className="main_continer">
        <Row className="global-ef">
          <Card.Title className="global-ef-title">
            {" "}
            Global Enabling Functions Management System Overview
          </Card.Title>
          <div className="col-12">
            <Table striped hover className="border">
              <tbody>
                <tr>
                  <th>Issuing ​Function</th>
                  <th style={{ width: "280px" }}>
                    Regulations in IMS IT Tools
                  </th>
                  <th>Regulation Landscape</th>
                  <th>Link&nbsp;to additional information</th>
                </tr>
                <tr>
                  <td>
                    <a
                      target={"_blank"}
                      href="https://bayernet.int.bayer.com/de-de/organization/enabling-functions/communications/home"
                    >
                      Communications
                    </a>
                    <br />
                  </td>
                  <td>
                    <a target={"_blank"} href="https://go.cnb/com-regs">
                      ​All Regulations in IMS (go/COM-Regs)
                    </a>
                  </td>
                  <td>​</td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <a
                      target={"_blank"}
                      href="https://bayernet.int.bayer.com/de-de/organization/enabling-functions/corporate-office/home"
                    >
                      Corporate Office
                    </a>
                  </td>
                  <td>
                    <a target={"_blank"} href="https://go.cnb/co-regs">
                      ​​All Regulations in IMS (go/CO-Regs)
                    </a>
                  </td>
                  <td>​</td>
                  <td></td>
                </tr>
                {/* <tr >
                                    <td>
                                        <a target={"_blank"} href="https://bayernet.int.bayer.com/sitecore/service/notfound.aspx?item=web:%7b710AD21B-8BA4-4EE3-94D9-ED92D20091DF%7d%40de-DE" >
                                            Corporate Quality</a>
                                    </td>
                                    <td >
                                        <a target={"_blank"} href="https://go.cnb/cq-regs">All Regulations in IMS (go/CQ-Regs)</a>
                                    </td>
                                    <td>​</td>
                                    <td>
                                        <a target={"_blank"} href="https://bayergroup.sharepoint.com/sites/020260/default.aspx">​CQ SharePoint</a>
                                    </td>
                                </tr> */}
                <tr>
                  <td>
                    ​
                    <a
                      target={"_blank"}
                      href="https://bayernet.int.bayer.com/sitecore/service/notfound.aspx?item=web:%7b710AD21B-8BA4-4EE3-94D9-ED92D20091DF%7d%40de-DE"
                    >
                      Corporate Quality
                    </a>
                  </td>
                  <td>
                    <a target={"_blank"} href="https://go.cnb/cq-regs">
                      All Regulations in IMS (go/CQ-Regs)
                    </a>
                  </td>
                  <td>​</td>
                  <td>
                    <a
                      target={"_blank"}
                      href="https://bayergroup.sharepoint.com/sites/020260/default.aspx"
                    >
                      ​CQ SharePoint
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    ​
                    <a
                      target={"_blank"}
                      href="https://bayernet.int.bayer.com/de-de/organization/enabling-functions/engineering-and-technology/home"
                    >
                      Engineering &amp; Technology
                    </a>
                  </td>
                  <td>
                    ​
                    <a target={"_blank"} href="https://go.cnb/et-regs">
                      ​​All Regulations in IMS (go/ET-Regs)
                    </a>
                  </td>
                  <td>​</td>
                  <td>
                    <a
                      target={"_blank"}
                      href="https://bayergroup.sharepoint.com/sites/002591/SitePages/Home.aspx"
                    >
                      ​HSEQ SharePoint
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    ​
                    <a
                      target={"_blank"}
                      href="https://bayernet.int.bayer.com/de-de/organization/enabling-functions/group-finance/home"
                    >
                      Group Finance
                    </a>
                  </td>
                  <td>
                    ​
                    <a target={"_blank"} href="https://go.cnb/fi-regs">
                      ​​All Regulations in IMS (go/FI-Regs)
                    </a>
                  </td>
                  <td>​</td>
                  <td>​</td>
                </tr>
                <tr>
                  <td>
                    ​
                    <a
                      target={"_blank"}
                      href="https://bayernet.int.bayer.com/de-de/organization/enabling-functions/human-resources/home"
                    >
                      Human Resources
                    </a>
                  </td>
                  <td>
                    ​
                    <a target={"_blank"} href="https://go.cnb/hr-regs">
                      ​​All Regulations in IMS (go/HR-Regs)
                    </a>
                  </td>
                  <td>
                    ​
                    <a
                      target={"_blank"}
                      href="https://mydoc.bayer.biz/cara/ContentByStatusCode/BCSDMS/view/0901f0da85b2e307:pub"
                    >
                      HR Regulation Landscape
                    </a>
                  </td>
                  <td>​</td>
                </tr>
                <tr>
                  <td>
                    ​
                    <a
                      target={"_blank"}
                      href="https://bayernet.int.bayer.com/de-de/organization/enabling-functions/internal-audit-and-risk-management/home"
                    >
                      Internal Audit &amp; Risk Management
                    </a>
                  </td>
                  <td>
                    ​​​
                    <a target={"_blank"} href="https://go.cnb/iarm-regs">
                      All Regulations in IMS (go/IARM-Regs)
                    </a>
                  </td>
                  <td>
                    <a
                      target={"_blank"}
                      href="https://mydoc.bayer.biz/cara/ContentByStatusCode/BCSDMS/view/0901f0da85b2e307:pub"
                    >
                      ​
                    </a>
                  </td>
                  <td>​</td>
                </tr>
                <tr>
                  <td>
                    ​
                    <a
                      target={"_blank"}
                      href="https://bayernet.int.bayer.com/de-de/organization/enabling-functions/it/home"
                    >
                      IT
                    </a>
                  </td>
                  <td>
                    ​
                    <a target={"_blank"} href="https://go.cnb/it-regs">
                      ​​All Regulations in IMS (go/IT-Regs)
                    </a>
                  </td>
                  <td>
                    <div>
                      <a
                        target={"_blank"}
                        href="https://mydoc.bayer.biz/cara/#BCSDMS%5bGuest%5d/NODE_DIMENSIONS/NODE_DIMENSIONS:7/NODE_DIMENSIONS:7%5e324371/NODE_DIMENSIONS:7%5e324371%5e92269"
                      >
                        - IT Leadership and Governance
                      </a>
                    </div>
                    <div>
                      <a
                        target={"_blank"}
                        href="https://mydoc.bayer.biz/cara/#BCSDMS%5bGuest%5d/NODE_DIMENSIONS/NODE_DIMENSIONS:7/NODE_DIMENSIONS:7%5e324371/NODE_DIMENSIONS:7%5e324371%5e282159"
                      >
                        - IT Risk &amp; Security Management
                      </a>
                    </div>
                    <div>
                      <a
                        target={"_blank"}
                        href="https://mydoc.bayer.biz/cara/#BCSDMS%5bGuest%5d/NODE_DIMENSIONS/NODE_DIMENSIONS:7/NODE_DIMENSIONS:7%5e324371/NODE_DIMENSIONS:7%5e324371%5e282157"
                      >
                        - IT Digital Platform
                      </a>
                    </div>
                    <div>
                      <a
                        target={"_blank"}
                        href="https://mydoc.bayer.biz/cara/#BCSDMS%5bGuest%5d/NODE_DIMENSIONS/NODE_DIMENSIONS:7/NODE_DIMENSIONS:7%5e324371/NODE_DIMENSIONS:7%5e324371%5e282158"
                      >
                        - IT Quality Management System
                      </a>
                    </div>
                    <div>
                      <a
                        target={"_blank"}
                        href="https://mydoc.bayer.biz/cara/#BCSDMS%5bGuest%5d/NODE_DIMENSIONS/NODE_DIMENSIONS:7/NODE_DIMENSIONS:7%5e324371/NODE_DIMENSIONS:7%5e324371%5e282160"
                      >
                        - IT Service Management
                      </a>
                    </div>
                    <div>
                      <a
                        target={"_blank"}
                        href="https://mydoc.bayer.biz/cara/#BCSDMS%5bGuest%5d/NODE_DIMENSIONS/NODE_DIMENSIONS:7/NODE_DIMENSIONS:7%5e324371/NODE_DIMENSIONS:7%5e324371%5e282162"
                      >
                        - IT Vendor Management
                      </a>
                    </div>
                    <div>
                      <a
                        target={"_blank"}
                        href="https://mydoc.bayer.biz/cara/#BCSDMS%5bGuest%5d/NODE_DIMENSIONS/NODE_DIMENSIONS:7/NODE_DIMENSIONS:7%5e324371/NODE_DIMENSIONS:7%5e324371%5e282161"
                      >
                        - IT Program &amp; Portfolio Management
                      </a>
                    </div>
                  </td>
                  <td>
                    ​
                    <a
                      target={"_blank"}
                      href="https://mydoc.bayer.biz/cara/#BCSDMS%5bGuest%5d/NODE_DIMENSIONS/NODE_DIMENSIONS:7/NODE_DIMENSIONS:7%5e324371/NODE_DIMENSIONS:7%5e324371%5e92229"
                    >
                      - Departmental Procedures
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    ​
                    <a
                      target={"_blank"}
                      href="https://bayernet.int.bayer.com/de-de/organization/enabling-functions/law-patents-and-compliance/home"
                    >
                      Law, Patents &amp; Compliance
                    </a>
                  </td>
                  <td>
                    <a target={"_blank"} href="http://go.cnb/lpc-regs">
                      ​​All Regulations in IMS (go/LPC-Regs)
                    </a>
                  </td>
                  <td>
                    <div>
                      <a
                        target={"_blank"}
                        href="https://mydoc.bayer.biz/cara/ContentByStatusCode/BCSDMS/view/0901f0da85a0ba2a:pub"
                      >
                        Overview LPC Regulation Landscape
                      </a>
                    </div>
                    <div>
                      <a
                        target={"_blank"}
                        href="http://sp-coll-bbs.bayer-ag.com/sites/030981/Pages/searchresult.aspx?query=%20AND%20%28%28askSourceStructureLevel2%3a%22%25C7%2582%25C7%25824c6177202f204d2641202f204c5043204250%22%29%20AND%20%28askSourceStructureLevel1%3a%22%25C7%2582%25C7%25824c61772c20506174656e7473202620436f6d706c69616e636520284c504329%22%29%29&amp;keyword=%2a&amp;selectedFilters=chk_%C7%82%C7%824c6177202f204d2641202f204c5043204250_askSourceStructureLevel2%3bchk_%C7%82%C7%824c61772c20506174656e7473202620436f6d706c69616e636520284c504329_askSourceStructureLevel1%3b&amp;function=Pages%20-%20Home&amp;tabName=undefined%22%7d&amp;keyword%22LastModified=undefined%22lmfe"
                      >
                        - Law / M&amp;A / LPC BP
                      </a>
                    </div>
                    <div>
                      <a
                        target={"_blank"}
                        href="http://sp-coll-bbs.bayer-ag.com/sites/030981/Pages/searchresult.aspx?query=%20AND%20%28%28askSourceStructureLevel1%3a%22%25C7%2582%25C7%25824c61772c20506174656e7473202620436f6d706c69616e636520284c504329%22%29%20AND%20%28askSourceStructureLevel2%3a%22%25C7%2582%25C7%2582436f6d706c69616e6365%22%29%29&amp;keyword=%2a&amp;selectedFilters=chk_%C7%82%C7%824c61772c20506174656e7473202620436f6d706c69616e636520284c504329_askSourceStructureLevel1%3bchk_%C7%82%C7%82436f6d706c69616e6365_askSourceStructureLevel2%3b&amp;function=Pages%20-%20Home&amp;tabName=undefined%22%7d&amp;keyword%22LastModified=undefined%22lmfe"
                      >
                        - Compliance
                      </a>
                    </div>
                    <div>
                      <a
                        target={"_blank"}
                        href="http://sp-coll-bbs.bayer-ag.com/sites/030981/Pages/searchresult.aspx?query=%20AND%20%28%28askSourceStructureLevel1%3a%22%25C7%2582%25C7%25824c61772c20506174656e7473202620436f6d706c69616e636520284c504329%22%29%20AND%20%28askSourceStructureLevel2%3a%22%25C7%2582%25C7%2582446174612050726976616379%22%29%29&amp;keyword=%2a&amp;selectedFilters=chk_%C7%82%C7%824c61772c20506174656e7473202620436f6d706c69616e636520284c504329_askSourceStructureLevel1%3bchk_%C7%82%C7%82446174612050726976616379_askSourceStructureLevel2%3b&amp;function=Pages%20-%20Home&amp;tabName=undefined%22%7d&amp;keyword%22LastModified=undefined%22lmfe"
                      >
                        - Data Privacy
                      </a>
                    </div>
                    <div>
                      <a
                        target={"_blank"}
                        href="http://sp-coll-bbs.bayer-ag.com/sites/030981/Pages/searchresult.aspx?query=%20AND%20%28%28askSourceStructureLevel1%3a%22%25C7%2582%25C7%25824c61772c20506174656e7473202620436f6d706c69616e636520284c504329%22%29%20AND%20%28askSourceStructureLevel2%3a%22%25C7%2582%25C7%2582496e74656c6c65637475616c2050726f7065727479202f20496e737572616e6365%22%29%29&amp;keyword=%2a&amp;selectedFilters=chk_%C7%82%C7%824c61772c20506174656e7473202620436f6d706c69616e636520284c504329_askSourceStructureLevel1%3bchk_%C7%82%C7%82496e74656c6c65637475616c2050726f7065727479202f20496e737572616e6365_askSourceStructureLevel2%3b&amp;function=Pages%20-%20Home&amp;tabName=undefined%22%7d&amp;keyword%22LastModified=undefined%22lmfe"
                      >
                        - Intellectual Property / Insurance
                      </a>
                    </div>
                  </td>
                  <td>
                    <a target={"_blank"} href="http://go.cnb/askLPC">
                      Legal, compliance &amp; data privacy solutions (go/askLPC)
                    </a>
                    <a target={"_blank"} href="http://go.cnb/lpc-platform">
                      LPC Platform (go/LPC-Platform)
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a target={"_blank"} href="https://leaps.bayer.com/">
                      Leaps by Bayer
                    </a>
                  </td>
                  <td>
                    ​
                    <a target={"_blank"} href="http://go.cnb/leaps-regs">
                      All Regulations in IMS (go/Leaps-Regs)
                    </a>
                  </td>
                  <td>​</td>
                  <td>​</td>
                </tr>
                <tr>
                  <td>
                    ​
                    <a
                      target={"_blank"}
                      href="https://bayernet.int.bayer.com/de-de/organization/enabling-functions/mergers-acquisitions-and-licensing/home"
                    >
                      Mergers, Acquisitions &amp; Licensing
                    </a>
                  </td>
                  <td>
                    ​
                    <a
                      target={"_blank"}
                      href="http://sp-coll-bbs.bayer-ag.com/sites/030981/Pages/searchresult.aspx?query=%20AND%20%28%28bySourceSystemTitle%3a%22%25C7%2582%25C7%25824d4152474f%22%29%20AND%20%28askSourceSubLevel%3a%22%25C7%2582%25C7%25824d41202d204d6572676572732c204163717569736974696f6e732026204c6963656e73696e67%22%29%29&amp;keyword=%2a&amp;selectedFilters=chk_%C7%82%C7%824d4152474f_bySourceSystemTitle%3bchk_%C7%82%C7%824d41202d204d6572676572732c204163717569736974696f6e732026204c6963656e73696e67_askSourceSubLevel%3b&amp;function=Pages%20-%20Home&amp;tabName=undefined%22%7d&amp;keyword%22LastModified=undefined%22lmfe"
                    >
                      All Regulations in IMS (go/MA-Regs)
                    </a>
                  </td>
                  <td>​</td>
                  <td>​</td>
                </tr>
                <tr>
                  <td>
                    ​
                    <a
                      target={"_blank"}
                      href="https://bayernet.int.bayer.com/de-de/organization/enabling-functions/procurement/home"
                    >
                      Procurement
                    </a>
                  </td>
                  <td>
                    ​
                    <a target={"_blank"} href="https://go.cnb/pro-regs">
                      ​​All Regulations in IMS (go/PRO-Regs)
                    </a>
                  </td>
                  <td>​</td>
                  <td>
                    <a
                      target={"_blank"}
                      href="http://sp-coll-bag.bayer-ag.com/sites/020467/default.aspx"
                    >
                      ​Procurement SharePoint
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a
                      target={"_blank"}
                      href="https://bayernet.int.bayer.com/de-de/organization/enabling-functions/public-affairs-science-sustainability-and-hse/home"
                    >
                      Public&nbsp;Affairs,&nbsp;Science,&nbsp;Sustainability
                      &amp; HSE (PASS &amp; HSE)
                    </a>
                  </td>
                  <td>
                    ​
                    <a target={"_blank"} href="https://go.cnb/pas-regs">
                      ​​
                    </a>
                    <a target={"_blank"} href="https://go.cnb/pashe-regs">
                      ​​All Regulations in IMS (go/PASHE-Regs)
                    </a>
                  </td>
                  <td></td>
                  <td>
                    ​
                    <a
                      target={"_blank"}
                      href="https://eur03.safelinks.protection.outlook.com/?url=https://bayergroup.sharepoint.com/sites/SSHE/&amp;data=05%7c01%7c%7c078362c17db5471e69f108dad2ac5f47%7cfcb2b37b5da0466b9b830014b67a7c78%7c0%7c0%7c638053935008191307%7cUnknown%7cTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7c3000%7c%7c%7c&amp;sdata=5uXS/DiN3uqfuEbK3Ynm5SiQPcvgFFr1NzMws/15JO8%3D&amp;reserved=0"
                    >
                      SSHE Sharepoint
                    </a>
                    <a
                      target={"_blank"}
                      href="https://eur03.safelinks.protection.outlook.com/?url=https://bayergroup.sharepoint.com/sites/MS/SitePages/go-HSEreg.aspx?OR%3DTeams-HL%26CT%3D1669796507137%26clickparams%3DeyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyNy8yMjEwMjgwNzIwMCIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%253D%253D&amp;data=05%7c01%7c%7c078362c17db5471e69f108dad2ac5f47%7cfcb2b37b5da0466b9b830014b67a7c78%7c0%7c0%7c638053935008191307%7cUnknown%7cTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7c3000%7c%7c%7c&amp;sdata=HH5VZuC7G02xyJmSl090YdxECF%2BfuhVpOQcUv2AnVWw%3D&amp;reserved=0"
                    >
                      SSHE Regulations page (go/SSHEreg)
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    ​
                    <a
                      target={"_blank"}
                      href="https://bayernet.int.bayer.com/de-de/organization/enabling-functions/sscdn/home"
                    >
                      Shared Service Center Delivery Network (SSCDN)
                    </a>
                  </td>
                  <td>
                    ​
                    <a target={"_blank"} href="https://go.cnb/bpm-regs">
                      ​​All Regulations in IMS (go/BPM-Regs)
                    </a>
                  </td>
                  <td>​</td>
                  <td>​</td>
                </tr>
                <tr>
                  <td>
                    ​
                    <a
                      target={"_blank"}
                      href="https://bayernet.int.bayer.com/de-de/organization/enabling-functions/strategy-and-business-consulting/home"
                    >
                      Strategy &amp; Business Consulting
                    </a>
                  </td>
                  <td>
                    ​
                    <a target={"_blank"} href="https://go.cnb/sbc-regs">
                      ​​All Regulations in IMS (go/SBC-Regs)
                    </a>
                  </td>
                  <td>​</td>
                  <td class="ms-rteTableFooterOddCol-default"></td>
                </tr>
              </tbody>
            </Table>
          </div>
          <Card.Text className="global-ef-title">
            Only&nbsp;content available for every employee and
            contractor&nbsp;is listed in IMS. Content with limited access needs
            to be opened from the IT tools (see&nbsp;IMS Home).&nbsp;
          </Card.Text>
          <Card.Text className="global-ef-title">
            Please don't hesitate to use the Feedback functionality to&nbsp;
            <a href="/#/feedback">give feedback</a>&nbsp;or to ask your
            questions.
          </Card.Text>
        </Row>
      </Container>
      {/* <Appfooter /> */}
    </>
  );
};
